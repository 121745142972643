import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "media-breakpoints"
    }}>{`Media Breakpoints`}</h1>
    <p>{`Define common responsive rules to support mobile first design standards and integrating a standard `}<a parentName="p" {...{
        "href": "/components/grid-system",
        "title": "Design Standards, Grid System"
      }}>{`grid system`}</a>{` structure.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p><strong parentName="p">{`(XL)`}</strong>{` – Desktop`}</p>
    <p><inlineCode parentName="p">{`@media (min-width: 1200px) { rules here }`}</inlineCode></p>
    <p><strong parentName="p">{`(LG)`}</strong>{` – Small Desktop or Large / Horizontal Orientation Tablet`}</p>
    <p><inlineCode parentName="p">{`@media (min-width: 992px) { rules here }`}</inlineCode></p>
    <p><strong parentName="p">{`(MD)`}</strong>{` – Vertical Orientation Tablet or Horizontal Orientation / Large Mobile`}</p>
    <p><inlineCode parentName="p">{`@media (min-width: 768px) { rules here }`}</inlineCode></p>
    <p><strong parentName="p">{`(SM)`}</strong>{` – Vertical Orientation Mobile or Horizontal Orientation Small Mobile`}</p>
    <p><inlineCode parentName="p">{`@media (min-width: 576px) { rules here }`}</inlineCode></p>
    <p><strong parentName="p">{`(XS)`}</strong>{` – Vertical Orientation Small Mobile`}</p>
    <p><inlineCode parentName="p">{`@media (max-width: 575px) { rules here }`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      